import React, { useState, useEffect } from 'react'
import httpClient from './helpers/httpClient'
import './PopupWindowTransaction.css'
import PopupDropdown from './PopupDropdown'
import CircularProgress from '@mui/material/CircularProgress'
import { CiSquarePlus } from "react-icons/ci"

export function PopupWindowTransaction({ isActive, setIsActive }) {

  const [propertySearchValue, setPropertySearchValue] = useState("")
  const [properties, setProperties] = useState([])
  const [newTransactionObj, setNewTransactionObj] = useState({
    type: "",
  })

  const fetchProperties = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/get`)
    if (response.status == 200) {
      setProperties(response.data)
    }
  }

  useEffect(() => {
    const popup = document.getElementById("popup-transaction")
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    fetchProperties()
  }, [])

  const handleSetStateNewTransactionType = (value) => {
    setNewTransactionObj({ ...newTransactionObj, type: value })
  }

  const closePopupWindow = () => {
    setIsActive(false)
  }

  console.log(properties)

  return (
    <div
      className={`popup__container overlay no-scrollbar ${isActive ? "active" : ""}`}
      id="popup-transaction"
      onClick={closePopupWindow}
    >
      <div
        className='popup__box no-scrollbar'
        onClick={e => e.stopPropagation()}
      >
        <div className='popout_body' id='popout-body-project'>
          <div className='popout_window__header'>
            <div className='popout_window__header--title'>Create Transaction</div>
            <button className='primary-button-header'>SAVE</button>
          </div>

          <div className='popout_body__content_container'>
            <div className='panel'>
              <div className="input_field--combo-grid">
                <label className="input_field__label" htmlFor="transaction_type">Type</label>
                <PopupDropdown
                  options={["Oorsprong", "Aankoop", "Verkoop"]}
                  value={newTransactionObj.type}
                  onChange={handleSetStateNewTransactionType}
                  title={"Select type"}
                  searchBar={false}
                />
              </div>

              <input
                className='search-bar--list'
                type="text"
                name="property_name"
                value={propertySearchValue}
                onChange={(e) => setPropertySearchValue(e.currentTarget.value)}
                placeholder="Search property"
              />
              <div className='properties_list'>
                {
                  properties
                    .filter(property => (
                      property?.cadastral_nr?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                      property?.location?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                      property?.function?.toLowerCase().includes(propertySearchValue.toLowerCase())
                    ))
                    .map((property, index) => {
                      return (
                        <div className='property-item' key={index}>
                          <div>{property.cadastral_nr}</div>
                          <div>{property.location}</div>
                          <div>{property.function}</div>
                        </div>
                      )
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}