import React, { useState } from 'react';
import './PopupDropdown.css';
import { MdClear } from "react-icons/md"
import Label from './Label'

const PopupDropdown = ({ options, value, onChange, title, fontSize, inputBoxHeight, searchBar, isMobile, labelView, labelColors }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  // Helper function to get the display value
  const getDisplayValue = (option) => {
    if (typeof option === 'object' && option !== null) {
      if (option.name) {
        return option.name;
      } else {
        return Object.entries(option)
          .filter(([key]) => key !== 'id')
          .map(([_, value]) => value)
          .join(' ');
      }
    }
    return option;
  };

  // Helper function to check if an option is selected
  const isSelected = (option) => {
    if (typeof option === 'object' && option !== null && typeof value === 'object' && value !== null) {
      return option.id === value.id;
    }
    return option === value;
  };

  return (
    <div className={`popup-dropdown ${isMobile ? "mobile" : ""}`}>
      <div className="popup-dropdown__selected" onClick={handleToggle} style={{ fontSize: isMobile ? "1rem" : fontSize, height: isMobile ? "60px" : inputBoxHeight }}>
        {
          value ?
            labelView ?
              <Label
                title={getDisplayValue(value)}
                fontSize={isMobile ? "1rem" : fontSize}
                labelColor={labelColors[value]}
              />:
              getDisplayValue(value)
            :
            title
        }
      </div>
      {
        isOpen && (
          <div className="popup-dropdown__overlay" onClick={handleToggle}>
            <div className="popup-dropdown__popup" onClick={(e) => e.stopPropagation()}>
              <div className="title">{title}</div>
              {searchBar && (
                <div className="popup-dropdown__search-container">
                  <input
                    className="popup-dropdown__search"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm && (
                    <button
                      className="popup-dropdown__clear-button"
                      onClick={() => {
                        setSearchTerm("");
                        onChange(null);
                      }}
                    >
                      <MdClear />
                    </button>
                  )}
                </div>
              )
              }
              <div className="popup-dropdown__options">
                {
                  options.filter(option => {
                    const optionValue = getDisplayValue(option);
                    return typeof optionValue === 'string' && optionValue.toLowerCase().includes(searchTerm.toLowerCase());
                  }).map((option) => (
                    <div
                      key={typeof option === 'object' ? option.id : option}
                      className={`popup-dropdown__option ${isSelected(option) ? 'selected' : ''}`}
                      onClick={() => handleSelect(option)}
                    >
                      {getDisplayValue(option)}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PopupDropdown;

PopupDropdown.defaultProps = {
  fontSize: "0.75rem",
  inputBoxHeight: "auto",
  searchBar: false,
  isMobile: false,
  labelView: false
}
