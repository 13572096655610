import React, { useEffect, useState } from 'react'
import './FileDropper.css'
import httpClient from '../components/helpers/httpClient'
import { InboxOutlined } from '@ant-design/icons'
import { Upload, message } from 'antd'
import { toast } from "react-toastify"
import CircularProgress from '@mui/material/CircularProgress'

function FileDropper({ type, fileType, hint, URL, setURL, disabled }) {
  const [loading, setLoading] = useState(false)
  const [fileURL, setFileURL] = useState(null)
  const [fileName, setFileName] = useState(null)

  const { Dragger } = Upload
  const documentPic = require("../assets/pictos/document.png")

  useEffect(() => {
    if (URL != null) {
      setFileURL(`${process.env.PUBLIC_URL}${URL}`)
    }
  }, [URL])

  useEffect(() => {
    const checkFileExistence = async () => {
      if (!fileName & URL == undefined) {
        setFileURL(null)
        return
      }

      try {
        const fileExists = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/files/check-existence/${fileName}?type=${fileType}`)
        if (fileExists.data) {
          switch (fileType) {
            case "product":
              setFileURL(`${process.env.PUBLIC_URL}/img/products/${fileName}`)
              setURL(`/img/products/${fileName}`)
              break
            case "product-files":
              setFileURL(`${process.env.PUBLIC_URL}/img/product-files/${fileName}`)
              setURL(`/img/product-files/${fileName}`)
              break
            default:
              setFileURL(`${process.env.PUBLIC_URL}/img/${fileName}`)
              setURL(`/img/${fileName}`)
              break

          }
        } else if (URL == undefined) {
          setFileURL(null)
        }
      } catch (error) {
        console.error('Error checking file existence:', error)
        console.log("hello3")

        setFileURL(null)
      }
    }

    checkFileExistence()
  }, [fileName])

  // Define the allowed file types
  let allowedFileTypes = []
  switch (type) {
    case "images":
      allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png']
      break
    case "documents":
      allowedFileTypes = ['application/pdf', 'text/plain', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      break
    case "audio":
      allowedFileTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg']
      break
    case "video":
      allowedFileTypes = ['video/mp4', 'video/webm', 'video/ogg']
      break
    default:
      // Include a broader range of file types for the default case
      allowedFileTypes = [
        'image/jpeg', 'image/jpg', 'image/png',
        'application/pdf', 'text/plain',
        'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'audio/mpeg', 'audio/wav', 'audio/ogg',
        'video/mp4', 'video/webm', 'video/ogg'
      ]
      break
  }

  const handleUpload = async (file) => {
    setLoading(true)

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await toast.promise(
        httpClient.post(process.env.REACT_APP_API_URL + `/api/files/upload?type=${fileType}`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }),
        {
          pending: "Uploading image...",
          success: `${file.name} file uploaded successfully`,
          error: `${file.name} file upload failed`
        }
      )

      console.log(response.data)
      setFileName(response.data)
      setLoading(false)

      // Return false to stop the internal upload process of Ant Design's Upload component
      return false
    } catch (error) {
      console.error('Upload error:', error)
      setLoading(false)

      return false
    }
  }

  const props = {
    name: 'file',
    multiple: true,
    disabled: disabled,
    beforeUpload: (file) => {

      if (!allowedFileTypes.includes(file.type)) {
        toast.error(`Upload failed: Only ${allowedFileTypes.join(', ')} files are allowed`)
        return Upload.LIST_IGNORE // Rejects the file. No need to return false.
      }

      // Call the function to handle upload
      handleUpload(file)

      // Prevent upload from starting automatically
      return false
    },
    onChange(info) {
      console.log(info.file, info.fileList)
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const handleHoverOver = (event) => {
    const deleteButton = event.currentTarget.firstChild
    if (!disabled) {
      deleteButton.classList.add("visible")
    }
  }

  const handleHoverOut = (event) => {
    const deleteButton = event.currentTarget.firstChild
    deleteButton.classList.remove("visible")
  }

  const handleDeleteImage = async (e, type) => {
    try {
      const response = await httpClient.delete(`${process.env.REACT_APP_API_URL}/api/files/delete?URL=${URL}&type=${type}`)

      console.log(response.data)
      setFileName('')
      setFileURL(null)
    } catch (error) {
      console.error('Delete error:', error)
    }
  }

  return (
    <div className='file_dropper__container'>
      {loading ? (
        <div className='loading_container'>
          <CircularProgress color='inherit' />
        </div>
      ) : (
        fileURL ? (
          type === "images" ? (
            <div className='image_wrapper' onMouseOver={handleHoverOver} onMouseLeave={handleHoverOut}>
              <button className='delete_button' onClick={(e) => handleDeleteImage(e, "image")}>delete</button>
              <img src={fileURL} alt="Uploaded" />
            </div>
          ) : (
            type === "documents" ? (
              <div className='document_wrapper' onMouseOver={handleHoverOver} onMouseLeave={handleHoverOut}>
                  <button className='delete_button' onClick={(e) => handleDeleteImage(e, "file")}>delete</button>
                  <a href={fileURL} download={fileName}>
                    <img src={documentPic} alt="Uploaded" />
                    <p>Download file</p>
                  </a>
              </div>
            ) : null // Add a fallback for types other than 'images' or 'documents'
          )
        ) : (
          <>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                {hint}
              </p>
            </Dragger>
          </>
        )
      )}
    </div>
  )
}

FileDropper.defaultProps = {
  "disabled": false
}

export default FileDropper