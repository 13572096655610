import React, { useState } from 'react'
import "./TransactionsPage.css"
import Header from '../components/Header'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import httpClient from '../components/helpers/httpClient'
import { PopupWindowTransaction } from '../components/PopupWindowTransaction'

function TransactionsPage() {
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [filters, setFilters] = useState({
    page: 1,
    sortType: null,
    sortAscending: true,
  })
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(20)
  const [popupTransactionActive, setPopupTransactionActive] = useState(false)

  const fetchTransactions = async () => {
    setLoading(true)

    const data = {
      filters: filters
    }
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/transactions`, data)
    setTransactions(response.data)
    setLoading(false)
  }

  const handleOnClickRow = (transactionID) => {
    console.log(transactionID)
  }

  const handleSetSortFilter = (sortType) => {
    console.log(sortType)
  }

  const handlePagination = (type) => {
    console.log(type)
  }

  return (
    <div className='template'>
      <Header>
      </Header>

      <MenuBar />

      <PopupWindowTransaction
        overflow="hidden"
        isActive={popupTransactionActive}
        setIsActive={setPopupTransactionActive}
      />

      <div className="template__body" id="body-transactions">
        <button className="floating__button--bottom-right" id="add--btn" onClick={() => setPopupTransactionActive(true)} />
        <div className='body__section big' id='all-companies-table'>
          <div className='table'>
            <div className='table__header-5'>
              <div className='table__header--field' onClick={() => handleSetSortFilter("id")}>id</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("company_number")}>tbd</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("name")}>tbd</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("city")}>tbd</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("email")}>tbd</div>
            </div>
            <div className='table__rows_container'>

              {
                loading ?
                  <LoadingSpinner /> :

                  transactions.map((transaction, index) => {
                    return (
                      <div className='table__row-5' key={index} onClick={() => handleOnClickRow(transaction.id)} data-transaction-id={transaction.id}>
                        <div className='table__row--field'>{transaction.id}</div>
                        <div className='table__row--field'></div>
                        <div className='table__row--field'></div>
                        <div className='table__row--field'></div>
                        <div className='table__row--field'></div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={() => handlePagination("previous")} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={() => handlePagination("next")} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionsPage